/* stylelint-disable */

// Mixins for common styles
@mixin button-base {
  background-color: #595353;
  color: white;
  border-radius: 2rem;
  font-size: 1rem;
  border: 1px solid #595353;

  &:hover {
    background-color: #fbf3e4;
    color: #595353;
    border: 1px solid #595353;
  }
}

@mixin arrow-after {
  &:after {
    content: '→';
    margin-left: 0.5rem;
  }
}

@mixin form-label {
  display: block;
  font-size: 0.875rem;
  color: #666;
  margin-bottom: 0.5rem;
}

#b2c-login {
  .heading {
    width: 100%;
    h1 {
      text-align: left;
      font-size: 1.25rem;
      font-weight: 500;
      padding-bottom: 1rem;
      padding-top: 0;
      margin-top: 0;
      line-height: 1.25;

      @media (min-width: 576px) {
        font-size: 2rem;
      }
    }
  }

  input {
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.5rem;
    background-color: white;
    margin-bottom: 1rem;
    &:focus {
      outline: none;
      border-color: #0066ff;
      box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1);
    }
  }

  .required {
    &:after {
      content: '*';
    }
  }

  #localAccountForm {
    width: 100%;
  }

  .intro {
    display: none;
  }

  .entry {
    h2 {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 1.5rem;
      color: #333;
    }

    label {
      @include form-label;
    }

    .password-label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0.5rem;
      a {
        font-size: 0.875rem;
        color: #0066ff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .buttons {
      button {
        @include button-base;
        padding: 0.3rem 1.5rem;
        display: flex;
        align-items: center;
        @include arrow-after;
        &:after {
          font-size: 1.5rem;
          font-weight: 300;
        }
      }
    }
  }

  .divider {
    display: none;
  }

  .create {
    grid-column: 2 / 3;
    @media (max-width: 768px) {
      grid-column: 1 / -1;
    }
    border-radius: 0.5rem;
    padding: 2rem;
    p {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      a {
        @include button-base;
        @include arrow-after;
        display: inline-flex;
        align-items: center;
        padding: 0.75rem 2rem;
        width: fit-content;
        text-decoration: none;
      }
    }
  }

  .error {
    color: #dc2626;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }

  .disclaimer-link {
    text-decoration: underline;
  }

  #attributeVerification {
    border-radius: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    .error.pageLevel {
      color: #dc2626;
      font-size: 0.875rem;
      margin-bottom: 1rem;
    }

    #attributeList {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        li {
          .attrEntry {
            label {
              @include form-label;
            }

            .helpLink {
              display: none;
            }

            .buttons.verify {
              margin-top: 0.5rem;

              button {
                @include button-base;
                padding: 0.75rem 2rem;
                font-size: 0.875rem;
                margin-right: 0.5rem;
              }
            }
          }

          fieldset {
            input {
              width: auto;
              padding: 0;
              margin-bottom: 0.5rem;
            }

            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            align-items: center;
            justify-content: left;
          }
        }

        .CheckboxMultiSelect {
          .attrEntry {
            label {
              display: flex;
              align-items: center;
              gap: 0.5rem;

              input[type='checkbox'] {
                width: auto;
                margin: 0;
              }
            }
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 1rem;

      button {
        @include button-base;
        padding: 0.75rem 2rem;

        &#cancel {
          background-color: transparent;
          color: #595353;
          border: 1px solid #595353;

          &:hover {
            color: white;
            background-color: #595353;
          }
        }
      }
    }
  }
}
