/* stylelint-disable scss/operator-no-unspaced */

@import '../../../assets/fonts/fonts';
@import './azure/login';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body > div:first-of-type > main > div:first-of-type {
    @apply flex min-h-screen flex-col;
  }

  .dev-wrapper {
    @apply relative pt-[3rem];
  }

  footer {
    @apply mt-auto;
  }

  html {
    @apply scroll-smooth;

    -webkit-tap-highlight-color: transparent;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
  }

  /* Hide scrollbar for Chrome, Safari, and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

input:-webkit-autofill {
  background-clip: text;
}

/* overwrite styles for react-day-picker */
/* stylelint-disable selector-class-pattern */
.rdp-day_selected {
  @apply font-bold text-black;
}

@media print {
  .grecaptcha-badge {
    display: none !important;
  }

  // hide trustbadge and  sonner-toaster
  [id*='trustbadge'],
  [class*='trustbadge'],
  [data-testid*='trustbadge'],
  [data-testid*='trustmark'],
  [class*='trustmark'],
  [data-sonner-toaster],
  [class*='printhidden'],
  [data-theme='light'] {
    display: none !important;
  }
}
