@font-face {
  font-family: 'Gotham Rounded';
  font-weight: 300;
  src: url('./GothamRounded-Light.otf') format('otf'), url('./GothamRoundedLight_21020.ttf') format('ttf'),
    url('./GothamRnd-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-weight: 300;
  font-style: italic;
  src: url('./GothamRounded-LightItalic.otf') format('otf'), url('./GothamRnd-LightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-weight: 400;
  src: url('./GothamRoundedBook_21018.ttf') format('ttf'), url('./GothamRnd-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-weight: 400;
  font-style: italic;
  src: url('./GothamRounded-BookItalic.otf') format('otf'), url('./GothamRnd-BookItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-weight: 500;
  src: url('./GothamRounded-Medium.otf') format('otf'), url('./GothamRoundedMedium_21022.ttf') format('ttf'),
    url('./GothamRnd-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-weight: 500;
  font-style: italic;
  src: url('./GothamRnd-MediumItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-weight: 700;
  src: url('./GothamRounded-Bold.otf') format('otf'), url('./GothamRoundedBold_21016.ttf') format('ttf'),
    url('./GothamRnd-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-weight: 700;
  font-style: italic;
  src: url('./GothamRnd-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'PF DinDisplay Pro';
  font-weight: 200;
  src: url('./PFDinDisplayPro-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'PF DinDisplay Pro';
  font-weight: 400;
  src: url('./PFDinDisplayPro-Regular.woff2') format('woff2'), url('./PFDinDisplayPro-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'PF DinDisplay Pro';
  font-weight: 700;
  src: url('./PFDinDisplayPro-Bold.woff2') format('woff2'), url('./PFDinDisplayPro-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Brandon Printed';
  font-weight: 400;
  src: url('./BrandonPrinted-One.otf') format('otf'), url('./BrandonPrinted-One.woff2') format('woff2');
}
