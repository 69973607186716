.list {
  margin-left: 1.25rem;
}

ol.list > li > ol.list {
  list-style-type: disc;
}

.list p {
  margin-bottom: 0;
  margin-top: 0;
}
